<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img src="@/assets/images/logo/mit.png">

        <h2 class="brand-text text-primary ml-1">
          MIT
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Mot de passe oublié ? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Entrer votre nouveau mot de passe
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-col md="12">
                <b-form-group
                  :label="$t('Password')"
                  label-for="a-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|min:8|password"
                  >
                    <b-form-input
                      id="a-password"
                      v-model="username"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- confirm password -->
              <b-col md="12">
                <b-form-group
                  :label="$t('Confirm Password')"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required|confirmed:Password"
                  >
                    <b-form-input
                      id="ac-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="Confirm Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Confirmer
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth'}">
              <feather-icon icon="ChevronLeftIcon" /> Retour à la connexion
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      username: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          await axios
            .post(
              '/auth/users/reset_password_confirm/',
              {
                uid: this.$route.params.uid,
                token: this.$route.params.token,
                new_password: this.password,
                re_new_password: this.username,
              },
            )
            .then(() => {
              this.load = 'false'
              setTimeout(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Vérifier Votre Email',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              }, 1500)
            })
            .catch(() => {
              this.load = 'false'
              setTimeout(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'EditIcon',
                    variant: 'danger',
                  },
                })
              }, 1000)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
